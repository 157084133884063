import React, { useRef, useState } from 'react'
import { CollapsibleQuadrantContainer } from './style'
import Typography from '../typography'

const CollapsibleQuadrant = (props: any) => {
  if (props.data) {
    let headline = props.data.headline ? props.data.headline : ''
    let subheadline = props.data.subheadline ? props.data.subheadline : ''
    let desktopAvatar = props.data.desktopAvatar ? props.data.desktopAvatar : ''
    let desktopChart = props.data.desktopChart ? props.data.desktopChart : ''
    let mobileAvatar = props.data.mobileAvatar ? props.data.mobileAvatar : ''
    let mobileChart = props.data.mobileChart ? props.data.mobileChart : ''
    let expandText = props.data.expandText ? props.data.expandText : ''
    let collapseText = props.data.collapseText ? props.data.collapseText : ''

    const getImageData = (imageSet: any) => {
      let imageOne = ''
      let imageOneAlt = ''
      let imageTwo = ''
      let imageTwoAlt = ''
      if (imageSet.length) {
        if (imageSet[0]) {
          if (
            imageSet[0].image &&
            imageSet[0].image.file &&
            imageSet[0].image.file.url
          )
            imageOne = imageSet[0].image.file.url
          if (imageSet[0].altText) imageOneAlt = imageSet[0].altText
        }
        if (imageSet[1]) {
          if (
            imageSet[1].image &&
            imageSet[1].image.file &&
            imageSet[1].image.file.url
          )
            imageTwo = imageSet[1].image.file.url
          if (imageSet[1].altText) imageTwoAlt = imageSet[1].altText
        }
      }
      return { imageOne, imageOneAlt, imageTwo, imageTwoAlt }
    }

    desktopAvatar = getImageData(desktopAvatar)
    desktopChart = getImageData(desktopChart)
    mobileAvatar = getImageData(mobileAvatar)
    mobileChart = getImageData(mobileChart)

    const [buttonText, setButtonText] = useState(expandText)
    const innerContainer = useRef<HTMLDivElement>(null)

    const buttonClick = () => {
      if (innerContainer.current) {
        if (innerContainer.current.classList.contains('Chart-Active')) {
          innerContainer.current.classList.remove('Chart-Active')
          setButtonText(expandText)
        } else {
          innerContainer.current.classList.add('Chart-Active')
          setButtonText(collapseText)
        }
        innerContainer.current.scrollIntoView()
      }
    }

    return (
      <CollapsibleQuadrantContainer data-cy="CollapsibleQuadrant">
        <div className="CollapsibleQuadrant__Outer-Container">
          <Typography type="header1">{headline}</Typography>
          <div
            ref={innerContainer}
            className="CollapsibleQuadrant__Inner"
            data-cy="CollapsibleQuadrant__Inner"
          >
            <div className="CollapsibleQuadrant__Content">
              {subheadline && (
                <Typography
                  className="CollapsibleQuadrant__Subheadline"
                  type="header4"
                >
                  {subheadline}
                </Typography>
              )}
              <div className="CollapsibleQuadrant__Columns">
                <div className="CollapsibleQuadrant__Column-One">
                  <div className="desktopWrapper">
                    {desktopAvatar.imageOne && (
                      <img
                        src={desktopAvatar.imageOne}
                        alt={desktopAvatar.imageOneAlt}
                      />
                    )}
                    {desktopChart.imageOne && (
                      <img
                        src={desktopChart.imageOne}
                        alt={desktopChart.imageOneAlt}
                      />
                    )}
                  </div>
                  <div className="mobileWrapper">
                    {mobileAvatar.imageOne && (
                      <img
                        src={mobileAvatar.imageOne}
                        alt={mobileAvatar.imageOneAlt}
                      />
                    )}
                    {mobileChart.imageOne && (
                      <img
                        src={mobileChart.imageOne}
                        alt={mobileChart.imageOneAlt}
                      />
                    )}
                  </div>
                </div>
                <div className="CollapsibleQuadrant__Column-Two">
                  <div className="desktopWrapper">
                    {desktopAvatar.imageTwo && (
                      <img
                        src={desktopAvatar.imageTwo}
                        alt={desktopAvatar.imageTwoAlt}
                      />
                    )}
                    {desktopChart.imageTwo && (
                      <img
                        src={desktopChart.imageTwo}
                        alt={desktopChart.imageTwoAlt}
                      />
                    )}
                  </div>
                  <div className="mobileWrapper">
                    {mobileAvatar.imageTwo && (
                      <img
                        src={mobileAvatar.imageTwo}
                        alt={mobileAvatar.imageTwoAlt}
                      />
                    )}
                    {mobileChart.imageTwo && (
                      <img
                        src={mobileChart.imageTwo}
                        alt={mobileChart.imageTwoAlt}
                      />
                    )}
                  </div>
                </div>
              </div>
              <button
                type="button"
                onClick={buttonClick}
                className="CollapsibleQuadrant__Button"
                data-cy="CollapsibleQuadrant__Button"
              >
                <Typography type="label">{buttonText}</Typography>
              </button>
            </div>
          </div>
        </div>
      </CollapsibleQuadrantContainer>
    )
  } else {
    return null
  }
}

export default CollapsibleQuadrant
